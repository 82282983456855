<template>
  <v-container class="px-0 pt-6">
    <div class="import-card mb-5">
      <v-row class="px-4 mb-3">
        <v-col cols="12" class="pb-0">
          <a
            :href="form.is_ship ? form.container_invoice_pdf : '#'"
            style="color: #ffffff; text-decoration: none"
            :target="form.is_ship ? '_blank' : ''"
          >
            <v-btn
              :disabled="form.is_ship ? false : true"
              color="#338A68"
              class="mr-2"
              style="border-radius: 15px; height: 26px; text-transform: unset"
            >
              <img class="pr-1" src="@/assets/image/icon-paper.png" alt="" />
              <span style="color: #ffffff">Ship</span>
            </v-btn>
          </a>

          <a
            :href="form.is_shore ? form.tax_invoice_file_path : '#'"
            style="color: #ffffff; text-decoration: none"
            :target="form.is_shore ? '_blank' : ''"
          >
            <v-btn
              :disabled="form.is_shore ? false : true"
              color="#62C4E3"
              style="border-radius: 15px; height: 26px; text-transform: unset"
            >
              <img class="pr-1" src="@/assets/image/icon-paper.png" alt="" />
              <span style="color: #ffffff">Shore</span>
            </v-btn>
          </a>
        </v-col>
        <v-col cols="6">
          <span class="step-text-head">สถานที่</span>
          <br />
          <span class="step-text-body">{{ form.return_place }}</span>
        </v-col>
        <v-col cols="6">
          <span class="step-text-head">วันเวลาที่ต้องถึง</span>
          <br />
          <span class="step-text-body">
            {{
              form.datetime_arrive
                ? convertFormatDate(form.datetime_arrive)
                : "-"
            }}
          </span>
        </v-col>
        <v-col
          cols="12"
          class="pt-0 mb-4"
          v-if="form.mode != 'Empty' && form.mode != 'Laden'"
        >
          <div class="step-text-head">ใบกำกับตู้</div>
          <div class="step-text-body my-2 ell-1">
            {{ form.container_invoice ? form.container_invoice : "-" }}
          </div>
          <a
            :href="form.container_invoice_pdf"
            v-if="form.container_invoice_pdf"
            style="color: #ffffff; text-decoration: none"
            target="_blank"
          >
            <v-btn
              dark
              depressed
              color="#59CB33"
              style="border-radius: 15px; height: 26px; text-transform: unset"
            >
              ดูเอกสาร
            </v-btn>
          </a>
        </v-col>
        <v-col cols="12" class="pt-0">
          <span class="step-text-head">คำสั่งพิเศษ</span>
          <br />
          <span class="step-text-body">{{
            form.remark ? form.remark : "-"
          }}</span>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col cols="12">
        <googlemap
          v-if="
            markers_current &&
            markers_current.lat &&
            markers_current.lng &&
            markers_place.lat &&
            markers_place.lng
          "
          :markers_current="markers_current"
          :markers_place="markers_place"
          :radius="
            form.mode != 'Empty' ? form.cy_place_radius : form.wh_factory_radius
          "
        />
        <v-card outlined v-else>
          <!-- no map -->
          <v-card-text class="text-center">
            <v-icon class="mb-2" color="grey lighten-1" size="60">
              mdi-map-marker-off
            </v-icon>
            <div class="font-24 grey--text text--darken-1">
              ไม่พบข้อมูลพิกัด
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#59CB33"
              v-bind="attrs"
              v-on="on"
              style="border-radius: 15px; height: 30px"
              class="float-right"
            >
              <span style="color: #ffffff; font-size: 12px"
                >ที่อยู่ออกใบเสร็จรับตู้</span
              >
            </v-btn>
          </template>

          <v-card style="border-radius: 15px">
            <v-card-title class="justify-center">
              <span style="font-size: 20px; font-weight: bold"
                >ที่อยู่ออกใบเสร็จรับตู้</span
              >
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" class="py-0 mt-6">
                  <span>ชื่อ</span>
                </v-col>
                <v-col cols="12" class="py-0 pb-3">
                  <span class="step-text-body">{{ form.customer }}</span>
                </v-col>
                <v-col cols="12" class="py-0">
                  <span>ที่อยู่</span>
                </v-col>
                <v-col cols="12" class="py-0 pb-3">
                  <span
                    class="step-text-body"
                    v-if="form.receipt_type_step4 == 1"
                    >{{ form.customer_address_step4 }}</span
                  >
                  <span class="step-text-body" v-else
                    >TN LOGISTICS CO.,LTD.</span
                  >
                </v-col>
                <v-col cols="12" class="py-0">
                  <span>เลขเสียภาษี</span>
                </v-col>
                <v-col cols="12" class="py-0 pb-2">
                  <span
                    class="step-text-body"
                    v-if="form.receipt_type_step4 == 1"
                    >{{ form.customer_tax_step4 }}</span
                  >
                  <span class="step-text-body" v-else>0205558021614</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="6">
        <template v-if="form.mode != 'Empty'">
          <span>ใบคืนตู้ (EIR)</span>
          <br />
          <take-photo @img="saveImageReturn" />
        </template>
      </v-col>
      <v-col cols="12" class="pt-3 mb-4">
        <v-btn
          class="text-center"
          color="#59CB33"
          style="border-radius: 15px; width: 100%; height: 70px"
          @click="finish"
        >
          <img class="mr-3" src="@/assets/image/correct.png" />
          <span style="color: #ffffff; font-size: 24px; font-weight: bold"
            >เสร็จสิ้น</span
          >
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="form.mode == 'Empty'">
      <v-col cols="12"></v-col>
      <v-col cols="12"></v-col>
      <v-col cols="12"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import Googlemap from "@/components/googlemap";
import TakePhoto from "@/components/takePhoto";
import moment from "moment";
export default {
  name: "step4",
  components: { TakePhoto, Googlemap },
  props: {
    form: Object,
  },
  data() {
    return {
      markers_place: null,
      markers_current: null,
      dialog: false,
      container_return: [],
    };
  },
  created() {
    if (this.form.mode == "Empty") {
      this.markers_place = {
        lat: parseFloat(this.form.wh_factory_latitude),
        lng: parseFloat(this.form.wh_factory_longitude),
      };
    } else {
      this.markers_place = {
        // lat: 13.814507,
        // lng: 100.5641517,
        lat: parseFloat(this.form.cy_place_latitude),
        lng: parseFloat(this.form.cy_place_longitude),
      };
    }
  },
  mounted() {
    this.geolocation();
  },
  methods: {
    geolocation() {
      this.globalLoading();
      navigator.geolocation.getCurrentPosition((position) => {
        this.markers_current = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });

      this.globalHideLoading();
    },
    finish() {
      let data = {
        job_done_container_image: "",
        container_return_image: "",
        container_return_receipt_image: "",
        step: 5,
      };
      if (this.container_return.length > 0)
        data.container_return_image = this.container_return[0].image;

      this.$emit("finish", data);
    },
    saveImageReturn(val) {
      this.container_return = val;
    },
    convertFormatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style scoped></style>
