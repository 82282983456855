<template>
  <div class="bg-main">
    <Header :back="true" />
    <v-container style="margin-bottom: 15%">
      <v-layout align-center>
        <v-flex xs4 class="pl-5">
          <span v-if="step == 1" style="font-weight: bold; font-size: 18px"
            >รับงาน</span
          >
          <span v-else-if="step == 2" style="font-weight: bold; font-size: 18px"
            >รับตู้</span
          >
          <span v-else-if="step == 3" style="font-weight: bold; font-size: 18px"
            >บรรจุสินค้า</span
          >
          <span v-else-if="step == 4" style="font-weight: bold; font-size: 18px"
            >ปิดงาน</span
          >
        </v-flex>
        <v-flex
          xs2
          :offset="form.mode != 'Empty' && form.mode != 'Laden' ? 0 : 2"
        >
          <v-divider
            style="position: relative; top: 24px; width: 100%"
            :style="{ background: step > 1 ? '#59CB33' : '#E5E5E5' }"
          />
          <div
            class="stepper-tnl text-center"
            :style="{ background: step > 0 ? '#59CB33' : '#E5E5E5' }"
          >
            <img class="image-step" src="@/assets/image/step1-import.png" />
          </div>
        </v-flex>
        <v-flex xs2 class="px-0">
          <v-divider
            style="position: relative; top: 24px; width: 100%"
            :style="{ background: step > 2 ? '#59CB33' : '#E5E5E5' }"
          />
          <div
            class="stepper-tnl text-center"
            :style="{ background: step > 1 ? '#59CB33' : '#E5E5E5' }"
          >
            <img class="image-step" src="@/assets/image/step2-import.png" />
          </div>
        </v-flex>
        <v-flex
          xs2
          class="px-0"
          v-if="form.mode != 'Empty' && form.mode != 'Laden'"
        >
          <v-divider
            style="position: relative; top: 24px; width: 100%"
            :style="{ background: step > 3 ? '#59CB33' : '#E5E5E5' }"
          />
          <div
            class="stepper-tnl text-center"
            :style="{ background: step > 2 ? '#59CB33' : '#E5E5E5' }"
          >
            <img class="image-step" src="@/assets/image/step3-export.png" />
          </div>
        </v-flex>
        <v-flex xs2 class="px-0">
          <div
            class="stepper-tnl text-center"
            :style="{ background: step > 3 ? '#59CB33' : '#E5E5E5' }"
          >
            <img class="image-step" src="@/assets/image/step4-import.png" />
          </div>
        </v-flex>
      </v-layout>

      <div v-if="step == 1" class="px-3">
        <step1 @nextStep="nextStep" :form="form" />
      </div>
      <div v-else-if="step == 2" class="px-3">
        <step2 @nextStep="nextStep" :form="form" />
      </div>
      <div v-else-if="step == 3" class="px-3">
        <step3 @nextStep="nextStep" :form="form" />
      </div>
      <div v-else-if="step == 4" class="px-3">
        <step4 @finish="finish" :form="form" />
      </div>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import Step1 from "@/components/export/step1";
import Header from "@/components/header";
import { mapState } from "vuex";
import Step2 from "@/components/export/step2";
import Footer from "@/components/footer";
import Step3 from "@/components/export/step3";
import Step4 from "@/components/export/step4";
export default {
  name: "main-import",
  components: { Step4, Step3, Footer, Step2, Header, Step1 },
  props: {
    id: { default: null },
  },
  data() {
    return {
      // step: 1,
    };
  },
  created() {
    this.scrollToTop();
  },
  computed: {
    ...mapState({
      step: (state) => {
        return state.job.step;
      },
      form: (state) => {
        return state.job.form;
      },
    }),
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    nextStep(val) {
      this.globalLoading();
      val.driver_job_id = this.form.driver_job_id;
      val.mode = this.form.mode;
      this.$store
        .dispatch("job/updateStep", val)
        .then((res) => {
          if (val.step == 2 && val.action == "REJECT")
            this.$router.push("/main");

          this.scrollToTop();
          this.globalHideLoading();
        })
        .catch((res) => {
          if (res.request.status == 400) {
            let message = JSON.parse(res.request.response).message;
            let text = "";
            message.forEach((x) => (text += x + "<br/>"));
            this.$swal({
              icon: "error",
              title: "",
              html: text,
              showConfirmButton: true,
            });
          } else if (res.request.status == 401) {
            this.$router.push("/");
          }
          // this.globalHideLoading();
        });
    },
    finish(val) {
      this.globalLoading();
      val.driver_job_id = this.form.driver_job_id;
      val.mode = this.form.mode;
      this.$store
        .dispatch("job/updateStep", val)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "",
            text: "ส่งข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 4000,
          }).then(() => {
            this.$store.dispatch("job/clearStep");
            this.$router.push("/main");
            this.globalHideLoading();
          });
        })
        .catch((res) => {
          if (res.request.status == 400) {
            let message = JSON.parse(res.request.response).message;
            let text = "";
            message.forEach((x) => (text += x + "\n"));
            this.$swal({
              icon: "error",
              title: "",
              html: text,
              showConfirmButton: true,
            });
          } else if (res.request.status == 401) {
            this.$router.push("/");
          }
          // this.globalHideLoading();
        });
    },
  },
};
</script>

<style scoped></style>
