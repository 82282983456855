<template>
  <v-container class="px-0 mt-4">
    <div class="import-card mb-5">
      <v-row class="px-4 mb-3">
        <v-col cols="6">
          <span class="step-text-head">เลขที่งาน</span>
          <br />
          <span class="step-text-body">{{ form.order_id }}</span>
        </v-col>
        <v-col cols="6">
          <span class="step-text-head">สถานที่รับตู้</span>
          <br />
          <span class="step-text-body">{{ form.cy_place }}</span>
        </v-col>
        <v-col cols="6">
          <span class="step-text-head">เลขที่อ้างอิง</span>
          <br />
          <span class="step-text-body">{{ form.inv_po }}</span>
        </v-col>
        <v-col cols="6">
          <span class="step-text-head">สถานะตู้</span>
          <br />
          <span class="step-text-body">{{ form.container_status }}</span>
        </v-col>
        <v-col cols="6" v-if="form.mode != 'Laden'">
          <span class="step-text-head">วันที่รับตู้</span>
          <br />
          <span class="step-text-body">{{ form.cy_date | formatDate }}</span>
        </v-col>
        <v-col
          cols="6"
          v-if="
            form.mode != 'Empty' &&
            form.mode != 'Laden' &&
            form.mode == 'Drop Load'
          "
        >
          <span class="step-text-head">Booking</span>
          <br />
          <span class="step-text-body">{{ form.booking_no }}</span>
        </v-col>
        <v-col
          cols="12"
          v-if="
            form.mode != 'Empty' &&
            form.mode != 'Laden' &&
            form.mode == 'Drop Load'
          "
        >
          <span class="step-text-head">Agent</span>
          <br />
          <span class="step-text-body">{{ form.agent }}</span>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col cols="12">
        <googlemap
          v-if="
            markers_current &&
            markers_current.lat &&
            markers_current.lng &&
            markers_place.lat &&
            markers_place.lng
          "
          :markers_current="markers_current"
          :markers_place="markers_place"
          :radius="
            form.mode != 'Laden' ? form.cy_place_radius : form.wh_factory_radius
          "
        />

        <v-card outlined v-else>
          <!-- no map -->
          <v-card-text class="text-center">
            <v-icon class="mb-2" color="grey lighten-1" size="60">
              mdi-map-marker-off
            </v-icon>
            <div class="font-24 grey--text text--darken-1">
              ไม่พบข้อมูลพิกัด
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#59CB33"
              v-bind="attrs"
              v-on="on"
              style="border-radius: 15px; height: 30px"
              class="float-right"
            >
              <span style="color: #ffffff; font-size: 12px"
                >ที่อยู่ออกใบเสร็จรับตู้</span
              >
            </v-btn>
          </template>

          <v-card style="border-radius: 15px">
            <v-card-title class="justify-center">
              <span style="font-size: 20px; font-weight: bold"
                >ที่อยู่ออกใบเสร็จรับตู้</span
              >
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" class="py-0 mt-6">
                  <span>ชื่อ</span>
                </v-col>
                <v-col cols="12" class="py-0 pb-3">
                  <span class="step-text-body">{{ form.customer }}</span>
                </v-col>
                <v-col cols="12" class="py-0">
                  <span>ที่อยู่</span>
                </v-col>
                <v-col cols="12" class="py-0 pb-3">
                  <span
                    class="step-text-body"
                    v-if="form.receipt_type_step2 == 1"
                    >{{ form.customer_address_step2 }}</span
                  >
                  <span class="step-text-body" v-else
                    >TN LOGISTICS CO.,LTD.</span
                  >
                </v-col>
                <v-col cols="12" class="py-0">
                  <span>เลขเสียภาษี</span>
                </v-col>
                <v-col cols="12" class="py-0 pb-2">
                  <span
                    class="step-text-body"
                    v-if="form.receipt_type_step2 == 1"
                    >{{ form.customer_tax_step2 }}</span
                  >
                  <span class="step-text-body" v-else>0205558021614</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="6">
        <span>รูปท้ายตู้</span>
        <br />
        <take-photo @img="saveImageContainer" />
        <br />
        <textarea
          v-if="form.mode != 'Laden'"
          v-model="data.pickup_container_no"
          class="text-box-small-tnl"
          placeholder="ระบุเบอร์ตู้"
        />
      </v-col>
      <v-col cols="6">
        <span v-if="form.mode != 'Laden'">เบอร์ซีล / ใบ EIR</span>
        <span v-else>รูปซีล</span>
        <br />
        <take-photo @img="saveImageSeal" />
        <br />
        <textarea
          v-if="form.mode != 'Laden'"
          v-model="data.seal_no"
          class="text-box-small-tnl"
          placeholder="ระบุเบอร์ซีล"
        />
      </v-col>
      <v-col cols="10" v-if="form.mode != 'Laden' && form.mode != 'Drop Load'">
        <span>น้ำหนักตู้</span>
        <br />
        <textarea
          v-model="data.cabinet_weight"
          class="text-box-small-tnl"
          placeholder="ระบุน้ำหนักตู้"
        />
      </v-col>
      <v-col cols="2" v-if="form.mode != 'Laden' && form.mode != 'Drop Load'">
        <span style="position: relative; right: 1%; top: 40%">Ton</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-3 mb-4">
        <v-btn
          class="text-center"
          color="#59CB33"
          style="border-radius: 15px; width: 100%; height: 70px"
          @click="next"
        >
          <img class="mr-3" src="@/assets/image/step2-submit-import.png" />
          <span style="color: #ffffff; font-size: 20px; font-weight: bold"
            >ส่งข้อมูลและดำเนินการต่อ</span
          >
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Googlemap from "@/components/googlemap";
import TakePhoto from "@/components/takePhoto";
export default {
  name: "step2",
  components: { TakePhoto, Googlemap },
  props: {
    form: Object,
  },
  data() {
    return {
      markers_place: null,
      markers_current: null,
      dialog: false,
      data: {
        driver_job_id: "",
        mode: "",
        pickup_container_image: "",
        pickup_container_no: "",
        seal_no_image: "",
        seal_no: "",
        cabinet_weight: "",
        step: 3,
      },
      container_image: [],
      seal_image: [],
    };
  },
  created() {
    if (this.form.mode == "Laden") {
      this.markers_place = {
        lat: parseFloat(this.form.wh_factory_latitude),
        lng: parseFloat(this.form.wh_factory_longitude),
      };
    } else {
      this.markers_place = {
        lat: parseFloat(this.form.cy_place_latitude),
        lng: parseFloat(this.form.cy_place_longitude),
      };
    }
  },
  mounted() {
    this.geolocation();
  },
  methods: {
    geolocation() {
      this.globalLoading();
      navigator.geolocation.getCurrentPosition((position) => {
        this.markers_current = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.globalHideLoading();
      });
    },
    next() {
      const { valid, msg } = this.validateContainerNo(
        this.data.pickup_container_no
      );

      if (valid) {
        if (this.container_image.length > 0)
          this.data.pickup_container_image = this.container_image[0].image;
        if (this.seal_image.length > 0)
          this.data.seal_no_image = this.seal_image[0].image;
        this.$emit("nextStep", this.data);
      } else {
        this.$swal({
          icon: "warning",
          title: "Warning!",
          text: msg,
          showConfirmButton: true,
        });
      }
    },
    validateContainerNo(text) {
      if (this.form.mode == "Laden") {
        return { valid: true, msg: "" };
      }
      let msg = "";
      let valid = true;
      var reg1 = /^[a-zA-Z]{4}/;
      var reg2 = /[0-9]{7}$/;

      if (!text) {
        valid = false;
        msg = "ระบุเบอร์ตู้";
        return { msg, valid };
      } else if (text.length != 11) {
        valid = false;
        msg = "เบอร์ตู้ ต้องมี 11 หลัก";
        return { valid, msg: msg };
      } else if (!reg1.test(text)) {
        valid = false;
        msg =
          "4 ตัวแรกต้องเป็นตัวอีกษร(3 ตัวแรกเป็นรหัสบริษัท และตัวที่ 4 ต้องเป็นอักษร U)";
        return { valid, msg: msg };
      } else if (text.charAt(3) != "U" && text.charAt(3) != "u") {
        valid = false;
        msg = "เบอร์ตู้ ตัวอักษรตำแหน่งที่ 4 ต้องเป็นตัวอักษร U";
        return { valid, msg: msg };
      } else if (!reg2.test(text)) {
        valid = false;
        msg =
          "เบอร์ตู้ ตัวอักษรตำแหน่งที่ 5 ถึงตัวสุดท้ายต้องเป็นตัวเลข 7 หลัก";
        return { valid, msg: msg };
      } else {
        return { valid, msg: msg };
      }
    },
    saveImageContainer(val) {
      this.container_image = val;
    },
    saveImageSeal(val) {
      this.seal_image = val;
    },
  },
};
</script>

<style scoped></style>
